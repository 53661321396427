import { useOidc, useOidcFetch } from '@axa-fr/react-oidc';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { handleUserLogOut } from 'redux/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { handleFetchCurrentUserDetails } from 'redux/profile/profile.action';
interface PrivateRouteProps {
  allowedRoles: string[];
}

export const PrivateRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const location = useLocation();
  const authToken = localStorage.getItem('hm.authToken');
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useOidc();
  const { fetch: oidcFetch } = useOidcFetch();
  const { currentUserDetails, error } = useAppSelector(
    (state) => state?.profileStore,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(handleFetchCurrentUserDetails(oidcFetch, logout));
  }, [currentUserDetails.userId]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) {
        logout('/');
        return;
      }

      if (currentUserDetails && currentUserDetails.roles) {
        if (
          !currentUserDetails.roles.some((role: string) =>
            allowedRoles.includes(role),
          )
        ) {
          // todo - Automatically navigate to the professional website if the role is not user
          return navigate('/unauthorized', {
            state: {
              from: location,
            },
            replace: true,
          });
        }
        return;
      }
    };

    fetchData();
  }, [authToken, currentUserDetails, allowedRoles, navigate, location]);

  if (!isAuthenticated) {
    dispatch(handleUserLogOut());
    logout('/');
  }

  return <Outlet />;
};
